/*
    Endeca control class for the typeahead endeca instance.
*/
var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.typeahead = site.endeca.instances.typeahead || {};

site.endeca.instances.typeahead.control = site.endeca.generic.Class.create(site.endeca.control, {
  initialize: function(args) {
    this.timer = 0;
    this._super(args);

    if (this.nodes.wrapper.length && this.nodes.inputElements.length) {
      var that = this;
      this.nodes.inputElements.bind('keyup', that, that.onKeyUp);
      this._watchToClose();
    }
  },
  capitalizeFirstLetter: function(str) {
    return (jQuery.trim(str) + '').replace(/^([a-z])|\s+([a-z])/g, function($1) {
      return $1.toUpperCase();
    });
  },
  onKeyUp: function(event) {
    var $this = jQuery(this);
    var that = event.data;
    clearTimeout(that.timer);
    that.timer = setTimeout(function() {
      that.currentInputElement = $this;
      var searchTerm = jQuery.trim($this.val());
      if (searchTerm === '') {
        that.nodes.popularSearch.removeClass('hidden');
        that.nodes.wrapper.addClass('hidden');
      }
      if (searchTerm != that.searchTerm && searchTerm.length >= that.configuration.minSearchLength) {
        searchTerm = searchTerm + "*";
        that.searchTerm = searchTerm;
        that.search({
          searchTerm: searchTerm
        });
      }
    }, 175);
  },
  _watchToClose: function() {
    var that = this;
    jQuery(document).bind('click', that, function(event) {
      var tgt = event.target;
      var that = event.data;

      if (jQuery(tgt).hasClass('search-form__field search-term') && (tgt && tgt.value < 1)) {
        that.nodes.popularSearch.removeClass('hidden');
        that.nodes.wrapper.addClass('hidden');
      }

    });
  },

  searchCompleted: function() {
    if (!this._super()) {
      return;
    }
    if (this.queries?.product?.jsonResult?.AggrRecords?.length) {
      var termResultDataRef = {};
      var termResultData = [];

      for (var i = 0; i < this.queries.product.jsonResult.AggrRecords.length; i++) {
        if (this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"]) {
          var matches;

          if (typeof this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"] == "string") {
            matches = [this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"]]
          } else {
            matches = this.queries.product.jsonResult.AggrRecords[i].Properties["DGraph.WhyDidItMatch"]
          }

          for (var j = 0; j < matches.length; j++) {
            if (matches[j] &&
              (
                matches[j].match(/p_PROD_RGN_NAME: ([^(]*).*/) ||
                matches[j].match(/Product Type: ([^(]*).*/) ||
                matches[j].match(/Product Category: ([^(]*).*/)
              )
            ) {
              if (!RegExp.$1.match(/^CAT/)) {
                if (termResultDataRef[RegExp.$1]) {
                  termResultDataRef[RegExp.$1]++;
                } else {
                  termResultDataRef[RegExp.$1] = 1;
                  termResultData.push({
                    'term': RegExp.$1,
                    'url': this.configuration.fullSearchBaseUrl + encodeURIComponent(RegExp.$1)
                  });
                }
              }
            }
          }
        }
      }


      if (this.results.terms.configuration.limit && termResultData.length > this.results.terms.configuration.limit) {
        termResultData.splice(this.results.terms.configuration.limit);
      }

      termResultData.sort(function(a, b) {
        if (termResultDataRef[a] > termResultDataRef[b]) {
          return -1;
        } else if (termResultDataRef[a] < termResultDataRef[b]) {
          return 1;
        }
        return 0;
      });


      this.results.terms.resultData = termResultData;
      this.results.terms.displayResults();
      this.results.terms.displayResultNodes();
      this.results.terms.show();
      this.nodes.popularSearch.addClass('hidden');
      this.nodes.suggestedSearch.show();
      this.hasResults = true;
    } else {
      this.results.terms.hide();
      this.nodes.suggestedSearch.hide();
    }

    this.results.products.resultData = this.catalogs.product.resultList;

    if (this.results.products.resultData.length) {
      // this is to trim the list of images, in cases where a product has more than one, to just one image
      // (to deal with the front-end's brittle handling of lists, not being able to do PROP.0 to get the
      // first property)
      for (var i = 0; i < this.results.products.resultData.length; i += 1) {
        var skuCount = this.results.products.resultData[i].skus.length;
        this.results.products.resultData[i].sku_count = skuCount;
        for (var prop in this.results.products.resultData[i]) {
          if (prop.indexOf("IMAGE_") === 0 && typeof this.results.products.resultData[i][prop] !== "string") {
            this.results.products.resultData[i][prop] = this.results.products.resultData[i][prop][0];
          }
          if (prop.indexOf("PROD_RGN_NAME") === 0) {
            var searchFortextColor = this.capitalizeFirstLetter(this.currentInputElement.val());
            this.results.products.resultData[i][prop] = this.results.products.resultData[i][prop].replace(searchFortextColor, '<span class="highlighted">' + searchFortextColor + '</span>');
          }
        }
      }

      this.results.products.displayResults();
      this.results.products.show();
      this.hasResults = true;
    } else {
      this.results.products.hide();
    }

    this.results.content.contentzones = this.configuration.contentzones;
    this.results.content.resultData = this.meta.supplementalContent;
    this.results.content.displayResults();

    if (!jQuery.isEmptyObject(this.results.content.resultData)) {
      this.meta.processSearchInfo({
        totalContentRecords: this.results.content.totalResults
      });
      //this.results.content.show();
      if (this.results.content.hasSoloResults) {
        this.hasResults = true;
      }
    } else {
      this.results.content.hide();
    }

    if (this.hasResults) {
      /* Add first search result */
      var searchTerm = this.searchTerm.replace('*', '');
      var showingStr = '';
      var outOfStr = '';
      var resultsStr = '';
      var showResults = '';

      if (Drupal.settings &&
        Drupal.settings.global_variables && Drupal.settings.global_variables.cr22) {
        showingStr = rb.endeca['showing'];
        outOfStr = rb.endeca['out_of'];
        resultsStr = rb.endeca['results'];
        this.results.seeResults.resultData = [{
          showingStr: showingStr,
          resultsStr: resultsStr,
          outOfStr: outOfStr,
          endingRecord: this.meta.searchInfo.endingRecord,
          totalRecords: this.meta.searchInfo.totalRecords,
          url: this.configuration.fullSearchBaseUrl + searchTerm
        }];
      } else {
        showResults = rb.endeca['show_results'];
        showResults = showResults.replace('::term::', searchTerm);
        this.results.seeResults.resultData = [{
          term: '<span class="show-search-results">' + showResults + '</span> <span class="search-count">(' +
            this.meta.searchInfo.totalRecords + ')</span>',
          url: this.configuration.fullSearchBaseUrl + searchTerm
        }];
      }
      this.results.seeResults.displayResults();
      this.results.seeResults.displayResultNodes();
      this.results.seeResults.show();
      this.nodes.popularSearch.addClass('hidden');
      if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled) {
        $(document).trigger('endeca.typeahead.complete');
      }
    }

    this.nodes.wrapper.removeClass('hidden');
    var me = this.nodes;
    this.nodes.wrapper.find('.result').bind('click', function(e) {
      var thisLink = this.getElementsByClassName('link');
      thisLink[0].click();
      //e.preventDefault();
      if (this.parentElement.className = "js-typeahead-gap-tag") {
        site.endeca.omniture.productClick();
      } else {
        //console.log("in predictive product click");
      }
      return true;
    });

    this.processOmniture();
    this.hideLoading();

  }
});
